/* CSS are here */

/* Navbar Searhbar */

@media only screen and (max-width: 1050px) {
  #react-select-2-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
  }
  .css-1y2yq9x-control {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
  }
}

/* table  */

.table {
  color: #212529;
}

.react-bootstrap-table-pagination {
  margin-top: 10px;
}
ul.pagination.react-bootstrap-table-page-btns-ul {
  float: right;
}
p.copyUrl {
  background: #e6e7eb;
  margin-bottom: -0.5%;
  cursor: pointer;
}
.page-item.active .page-link {
  color: #000 !important;
  background-color: rgb(223, 242, 243) !important;
  border-color: #33cdd1 !important;
}
.page-link {
  color: #000 !important;
  background-color: #ffffff !important;
  border-color: #33cdd1 !important;
}
page-link:hover {
  background-color: red !important;
}
table.table.table-bordered thead {
  background-color: #33cdd1;
  color: white;
  font-family: novab;
}
table.table.table-bordered tbody {
  font-family: nova;
}

.table-bordered td,
.table-bordered th {
  /* border: 0px solid #dee2e6 !important; */
  border-left: 0px solid #000 !important;
  border-right: 0px solid #000 !important;
}

.table-bordered th {
  background: #33cdd1;
  color: white;
}

.table-bordered td:nth-child(2n + 4),
.table-bordered th:nth-child(2n + 4) {
  min-width: 120px;
}

.table-bordered td:nth-child(2n + 3),
.table-bordered th:nth-child(2n + 3) {
  min-width: 120px;
}

/* dff2f3 */
.btn-secondary {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #33cdd1 !important;
}
.btn-secondary:focus {
  color: #000 !important;
  background-color: #dff2f3 !important;
  border-color: #33cdd1 !important;
}
@font-face {
  font-family: "nova";
  src: url("./assets/fonts/regular.otf");
}

@font-face {
  font-family: "novab";
  src: url("./assets/fonts/bold.otf");
}

html,
body {
  margin: 0;
  border: 0;
  width: 100%;
}

body {
  /* padding: 0 50px; */
  background: #e7ecef !important;
  font-family: novab, sans-serif;
  overflow-x: hidden;
  overflow-y: auto !important;
}

#main {
  margin: 0 auto;
  /* in case you want to set a fixed width on this as well */
}

span.error-message {
  color: red;
}

/* table */

.tablehome {
  padding: 0 50px;
}

.tablebtn {
  /* margin-top: auto; */
  margin: auto;
}
.spacer {
  flex: 1;
}

.monthly-lead-card {
  height: 100%;
}

.daily-lead-card {
  height: 100%;
  margin-top: 8px;
}

.css-2b097c-container {
  width: 250px !important; /*200px */
}

span.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-yk16xz-control {
  border-width: 0px !important;
}

.css-1pahdxg-control {
  border-color: #fff !important;
  box-shadow: none !important;
}

.nodData {
  background-color: #ff5a6a;
  color: #fff;
  border: 1px solid #ff5a6a;
  padding: 12px;
  text-align: center;
  font-size: 18px;
  font-family: novab;
}
.nosubs {
  background-color: #d6af4b;
  color: #fff;
  border: 1px solid #ffd05a;
  padding: 12px;
  text-align: center;
  font-size: 18px;
  font-family: novab;
}

.leftbar.p-xl-3 {
  padding-bottom: 0 !important;
}

/* login */

.login-page {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.login-page > .row {
  width: 100%;
}

h3.signin {
  font-family: novab;
}

input.form-control.showPass {
  display: flex;
}
.form-group {
  margin-bottom: 1rem;
}

.signupInvitation {
  text-align: center;
  margin-top: 15px;
}

#input_containerp {
  position: relative;
  padding: 0;
  margin: 0;
}

#inputp {
  height: 20px;
  margin: 0;
  padding-left: 30px;
}

#input_img {
  position: absolute;
  bottom: 12px;
  left: 88%;
  height: 50%;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.custom-loader .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
  margin-top: 40vh;
}

.custom-loader .modal-body {
  align-self: center !important;
}

button.btn.btn-primary.btn-block.groupboss {
  background-color: #33cdd1;
  height: 50px;
  font-weight: bold;
  border-style: none;
  font-family: novab;
}

.btn-block {
  display: block;
  width: 100%;
}

input.form-control {
  height: 50px;
}
input.form-control:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.loginForm {
  font-family: nova;
}

.signupForm {
  font-family: nova;
}

.leftbarpadding {
  padding: 10%;
  height: 100%;
}

img.homeImgback2 {
  display: block;
  width: 70%;
}

img.homeImgback3 {
  display: block;
  width: 90%;
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
  img.homeImgback3 {
    width: 100%;
  }
  img.homeImgback2 {
    display: block;
    width: 80%;
  }
  .apptop {
    width: 100% !important;
  }
}

a.linto {
  margin-top: 8px !important;
  text-decoration: none !important;
}

/* (1920x1080) Full HD Display */

@media screen and (max-width: 1920px) {
  img.homeImgback3 {
    width: 100%;
  }
  .apptop {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .tableButton {
    margin-top: 15px;
  }
  a.linto {
    margin-left: 8px;
  }
}

@media screen and (max-width: 992px) {
  #input_img {
    left: 84%;
  }
  .dashboard-card.yearly {
    margin-top: 30px;
  }
  .spacer {
    flex: 0;
  }
}

/* Apptopbar */

.navbar-nav > li:first-child {
  border: none;
}

.navbar-nav > li:nth-child(4) {
  border: none;
}

.apptop {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  font-family: novab, sans-serif;
  width: 100% !important;
}

.dropdown-item {
  font-family: nova, sans-serif;
}

@media screen and (min-width: 1056px) {
  .navbar-nav > li {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }
  li.nav-item {
    margin: 0px 3vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  .homeHead.search {
    float: right;
  }
  .apptop {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 600px) {
  .homeHead.search {
    float: right;
  }
  .secondChart {
    margin-top: 10%;
  }
}

@media screen and (max-width: 767px) {
  .loginForm {
    padding: 25px 25px;
  }
  .signupForm {
    margin-top: 10%;
    padding: 0 25px;
  }
  .signin {
    text-align: center;
  }
  #input_img {
    left: 86%;
  }
  .homeImgback2 {
    width: 60% !important;
  }
  .homeImgback3 {
    width: 60% !important;
    margin: 0 auto;
  }
  .leftbar {
    height: 60vh !important;
  }
  .leftbar-logo-text {
    align-items: center;
  }
  .fb-audience-button {
    width: 100%;
    margin-top: 20px;
  }
  .p-button-secondary {
    margin-top: 20px !important;
  }
  .p-inputgroup-addon {
    margin-top: 20px;
    justify-content: start;
  }
  .navbar-nav {
    margin-top: 25px;
    padding-left: 45px;
  }
  .navbar-nav li.nav-item {
    margin-top: 20px !important;
  }
  .today-button {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .loginForm {
    padding: 25px 25px;
  }
  .signupForm {
    margin-top: 10%;
    padding: 0 25px;
  }
  .signin {
    text-align: center;
  }
  #input_img {
    left: 86%;
  }
}

@media only screen and (max-width: 1200px) {
  li.nav-item {
    margin: 0px 2vw !important;
  }
}

/* CSS for dashboard: */

.p-col-12.p-md-1.p-sm-1.test {
  width: 50px;
}

.homesize {
  width: 50px;
}

.homeImg {
  position: relative;
  color: white;
}

.numberOfScrap2 {
  font-size: 30px;
  text-align: center;
  color: white;
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-full {
  display: inline-block;
  width: calc(100% - 2.143em);
}

.p-button {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 5px;
  border-radius: 3px;
}

.groupName {
  padding-left: 0;
}

.CSVButtons {
  padding-left: 0;
}

.groupsTimeRangeContainer {
  padding: 0;
}

.numberOfScrap {
  font-size: 30px;
  margin-left: 80px;
  color: white;
  font-weight: 800;
}

.homeMargin {
  margin-top: 0.5%;
}

.tableMargin {
  margin-top: 1.5%;
  padding: 0 50px;
  font-family: nova, sans-serif;
}

.tableMargin2 {
  padding: 0 50px;
  font-family: nova, sans-serif;
  margin-bottom: -1.5%;
}

.tableMargin2 {
  margin-top: 2.5%;
  padding: 0 50px;
}

.tablePadding {
  padding: 0 30px;
}

.homeMargin2 {
  margin-top: 3.5%;
}

.homeHead {
  font-size: 20px;
  font-weight: bold;
  color: #52575f;
}
.dashboardStyle {
  display: flex;
  align-items: center;
}

.homeHead2 {
  text-align: left;
  font-size: 16px;
  font-family: nova, sans-serif;
  font-weight: bold;
  color: #52575f;
}

.homeHeadC2 {
  font-size: 18px;
  font-weight: bold;
  color: #52575f;
}

.homeHeadC3 {
  font-size: 30px;
  font-weight: bold;
  color: #52575f;
}

.homeHeadC3x {
  font-size: 15px;
  color: #909497;
}

.chartPad {
  padding: 0 30px;
}

.homeHead3 {
  font-size: 13px;
  font-family: noval, sans-serif;
  font-weight: 100;
  text-align: left;
  color: #909497;
}

.p-dropdown.p-component.p-dropdown-clearable {
  outline: none;
  padding: 5px;
}

body .p-dropdown {
  border: none;
}

.p-col-6.leftHome {
  margin-left: 1.7%;
}

.homeGroup {
  float: right;
}

.dashcardBox {
  background-color: #88dcdd;
  width: 80%;
  border: 15px solid #88dcdd;
  padding: 50px;
  margin: 20px;
  border-radius: 15px;
  margin-left: 7%;
}

.p-button-text-only .p-button-text {
  color: white;
}

.p-button-label {
  color: #fff;
  padding: 4px;
}

.dashcardBox2 {
  color: white;
}

.dashcard2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: absolute;
}

.dashcard2:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* .dashcard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
} */

.dashcard {
  background-color: #fff;
  transition: 0.3s;
  border-radius: 5px;
}

.dashcard:hover {
  border-radius: 5px;
}

/* body .p-component {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
body .p-component:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
} */

.p-col-6.dash {
  width: 100%;
}

.p-grid.render {
  margin-top: 5%;
}

.p-grid.dashboardM {
  margin-top: 2%;
}

.p-grid.dashboard {
  background-color: #e4eaea;
}

.p-card.p-component.appTop {
  width: 100%;
}

.p-card.p-component.appTop {
  position: fixed;
}

span.dashboardClick1 {
  cursor: pointer;
}

span.dashboardClick2 {
  cursor: pointer;
}

.click1 {
  font-size: 20px;
  margin-top: 14px;
}

.click {
  font-size: 20px;
  margin-top: 14px;
}

.contact {
  font-size: 20px;
  margin-top: 14px;
  text-align: right;
  color: #909497;
}

.logout {
  font-size: 20px;
  margin-top: 14px;
  color: #909497;
}

.logout .dropdown {
  float: right;
  overflow: hidden;
}

.logout .dropdown .dropbtn {
  font-size: 20px;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
}

button.dropbtn {
  color: #52575f;
}

.logout .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .logout {
  float: none;
  color: #52575f;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
}

.logout .dropdown-content .logout:hover {
  background-color: #ddd;
  cursor: pointer;
}

.logout .dropdown:hover .dropdown-content {
  display: block;
}

span.dashboardClick2 {
  cursor: pointer;
  padding-left: 3em;
}

@media screen and (min-width: 1056px) {
  .dropdown-menu-right {
    margin-top: 45px;
  }
}

@media only screen and (min-device-width: 1056px) {
  .login-page2 {
    background: #ededed;
    margin-top: 60%;
    width: 110%;
  }
}

@media only screen and (max-device-width: 600px) {
  .login-page2 {
    background: #ededed;
    margin-top: 60%;
    padding: 0 10px;
  }
}

.login-page2 {
  background: #ededed;
  margin-top: 60%;
}

.signup-page2 {
  background: #ededed;
  margin-top: 30%;
}

.verify-page2 {
  background: #ededed;
  margin-top: 50%;
}

.form2 {
  position: relative;
  z-index: 1;
  margin: 0 auto 100px;
  padding: -15px;
  text-align: center;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
}

.form2 input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #ffffff;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form2 .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

.form2 .message a {
  color: #4caf50;
  text-decoration: none;
}

.form2 .register-form2 {
  display: none;
}

.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .info {
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #ef3b3a;
}

.leftbar {
  background-color: white;
  height: 100vh;
}

span.sloganLogin {
  padding-left: 10px;
  font-size: 22px;
  color: #52565e;
  font-family: nova;
}

p.sloganLogin2 {
  color: #52565e;
  font-family: nova;
}

h2.signIn {
  color: #52565e;
}

button.loginBtn {
  width: 100%;
  border: 0;
  padding: 16px;
  background-color: #3ccbcf;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3 ease;
  /* Safari prior 6.1 */
  transition: all 0.3 ease;
  color: white;
}

.form2 button:hover,
.form2 button:active,
.form2 button:focus {
  background: #38a2a5;
  color: white;
  outline: 0;
  box-shadow: none !important;
}

.verify button:hover,
.form2 button:active,
.form2 button:focus {
  background: #38a2a5;
  color: white;
  outline: 0;
  box-shadow: none !important;
}

label.p-checkbox-label.forget {
  padding-left: 6em;
  text-decoration: underline;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #3ccbcf;
  background-color: #3ccbcf;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #3ccbcf;
  background-color: #3ccbcf;
}

span.lifeTime {
  color: #329e8a;
  font-size: 16px;
  font-weight: bold;
}

.p-col-12.verify {
  text-align: center;
  color: #3ccbcf;
}

.p-col-12.successMsg {
  text-align: center;
}

.firstL {
  font-size: 30px;
  font-weight: bold;
  color: #52575f;
}

.secondL {
  font-size: 20px;
  font-weight: bold;
  color: #909497;
  margin-top: -10px;
}

/*loader*/
.loader-modal {
  justify-content: center;
  align-items: center;
}

/*Toastify*/
.Toastify__toast {
  min-height: 30px !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}
.Toastify__toast--default {
  background: rgb(81, 163, 81) !important;
}
.Toastify__toast--info {
  background: rgb(47, 150, 180) !important;
}
.Toastify__toast--success {
  background: rgb(81, 163, 81) !important;
}
.Toastify__toast--warning {
  background: rgb(248, 148, 6) !important;
}
.Toastify__toast--error {
  background: rgb(189, 54, 47) !important;
}
.Toastify__toast-container--top-right {
  top: 4em !important;
}

/*Dashboard*/
.dashboard-card {
  border-color: #fff;
  border-radius: 5px;
}
.dashboard-card-body {
  padding-top: 0;
}
.dashboard-card-header {
  background: #fff;
  border-bottom: none;
  padding-bottom: 0;
}

/* Table */

.table-responsive {
  border-radius: 10px;
}
.table-responsive td {
  border: 1px solid #e5e7ea;
}
.table-responsive tr {
  border: 2px solid #e5e7ea;
  border-top: none;
  border-bottom: none;
}
.table-responsive thead tr {
  border: 2px solid #33cdd1;
  border-top: none;
  border-bottom: none;
}
.table-responsive thead th {
  border-bottom: none;
}
.table-bordered th {
  border: none;
}

p.copyUrl {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 3px;
  background: #e5e7ea;
  width: 84%;
}
.p-button-secondary {
  border: none !important;
}

/*React-dates*/
.DateRangePicker {
  height: 43px;
  max-width: 100%;
}
.DateRangePickerInput {
  height: 43px;
  width: 99%;
  display: flex;
  border-radius: 4px;
  border: none;
}
.DateInput {
  height: 38px;
  margin-left: 5px;
  margin-top: 1px;
}
.DateInput_input {
  height: 38px;
  font-size: 14px;
  color: #808080;
}
.DateInput_input__focused {
  border-bottom: 2px solid #33cdd1;
}
.CalendarMonth_caption {
  color: #33cdd1;
}
.DayPicker_weekHeader {
  color: #33cdd1;
}
.date-range-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
.date-range-picker-button:hover {
  background-color: #32a8aa !important;
  color: white !important;
  border: 2px solid #32a8aa !important;
}
.date-range-picker-button:focus {
  background-color: #32a8aa !important;
}
.date-range-picker-button {
  background: #33cdd1 !important;
  border: 2px solid #33cdd1;
  color: #fff !important;
  height: 38px;
  padding: 2px 10px;
}

.fb-audience-button {
  color: #4267b2 !important;
  background: #fff;
  /* border: 1px solid #4267b2; */
  border-color: #4267b2 !important;
  padding: 0.375rem 0.5rem;
  font-size: 14px;
  min-height: 41px;
  font-family: novab, sans-serif;
}

.fb-audience-button:focus {
  background: #4267b2 !important;
  color: #fff !important;
  border-color: #4267b2 !important;
  box-shadow: none;
}

#emailInputId:focus {
  border-color: #33cdd1;
}

/* css of pricing */

.pricing {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 23% 23%;
  justify-content: center;
  align-content: center;
  grid-column-gap: 60px;
  padding-bottom: 34px;
}
@media (max-width: 1200px) {
  .pricing {
    grid-template-columns: 35% 35%;
    align-content: space-evenly;
    grid-gap: 40px;
  }
}
@media (max-width: 768px) {
  .pricing {
    grid-template-columns: 40% 40%;
    align-content: space-evenly;
  }
}
@media (max-width: 575px) {
  .pricing {
    grid-template-columns: 80%;
    align-content: space-evenly;
  }
}
.pricing > div {
  display: grid;
  grid-template-columns: auto;
  /* grid-row-gap: 20px; */
  grid-template-rows: 1fr auto 0.2fr;
  border: 1px solid #cacedd;
  font-family: "nova";
  border-radius: 20px;
}
.div1Top {
  position: relative;
  display: grid;
  grid-template-columns: 75%;
  padding: 15px 5px 25px 5px;
  text-align: center;
  justify-content: center;
  align-content: space-between;
  grid-row-gap: 15px;
  border-bottom: 1px solid rgba(194, 204, 225, 1);
  border-radius: 20px 20px 0 0;
}
.div1Top > .popular {
  position: absolute;
  right: 15px;
  top: -13px;
  background-color: #33cdd1;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 4px;
}
.yearlyPopular {
  font: Bold 20px nova;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #33475b;
  opacity: 1;
}
.dollar {
  font: Bold 30px nova;
  letter-spacing: 0px;
  color: #33475b;
  opacity: 1;
}
.trial {
  font: Regular 16px nova;
  letter-spacing: 0px;
  color: #33475b;
  opacity: 0.6;
}
.trialBtn {
  padding: 10px 25% 10px 25%;
  background-color: #33cdd1;
  border: none;
  border-radius: 4px;

  text-align: center;
  font: Regular 16px nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.div1Bottom {
  padding-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  align-content: space-between;
  grid-row-gap: 15px;
  padding-left: 5%;
}
.textStyle {
  color: #85919d;
  font-size: 16px;
}
.tickImage {
  background-color: #d6f5f6;
  border-radius: 25px;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
}
.purchaseBtn {
  padding: 10px 31% 10px 31%;
  background-color: #33cdd1;
  border: none;
  border-radius: 4px;

  text-align: center;
  font: Regular 16px nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.helpDiv {
  background-color: #ffffff;
  border: 1px solid #acc4d0;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
}
.questionDiv {
  width: 20px;
  height: 20px;
  background-color: #33475b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.helpText {
  font-size: 16px;
  font-weight: 400;
  color: #33475b;
}
.question {
  color: white;
}
.dashboarNavbar {
  display: flex;
  padding-bottom: 6px;
  align-items: center;
}
.searchIconDiv {
  width: 20px;
  position: fixed;
  margin-left: 20px;
  z-index: 1;
  margin-top: 9px;
}
.groupsDiv {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.groupsSpan {
  font-size: 16px;
  font-weight: bold;
  color: #33475b;
}
.gettingText {
  font-size: 36px;
  font-weight: bold;
  color: #33475b;
  margin-top: 140px;
  margin-bottom: 30px;
}
.gettingTextNormal {
  font-size: 20px;
  font-weight: 400;
  color: #33475b;
  opacity: 0.7;
}
.introText {
  text-align: left;
}
.introText {
  overflow-x: hidden;
  /* display: flex;
  align-items: center; */
  margin-left: 13%;
}
.gettingVideo {
  display: grid;
  grid-template-columns: 420px 45% 15%;
  align-items: center;
  margin-top: 73px;
  margin-bottom: 20px;
}
.gettingVideoSecond {
  display: grid;
  grid-template-columns: 528px 40% 15%;
  align-items: center;
  margin-top: 73px;
  margin-bottom: 20px;
}
.downloadExtension {
  /* margin-left: 5%; */
  margin-right: 5%;
  padding: 0 10%;
}

.imageExtension {
  /* height: 415px;
  width: 100%; */
  height: 500px;
}

.arrowRightClass {
  display: block;
}

.arrowRightClassHover {
  display: none;
}

.arrowBox {
  height: 60px;
  width: 60px;
  background-color: #ffffff;
  box-shadow: 0px 26px 26px #47b6ba29;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrowBox:hover {
  background-color: #33cdd1;
}
.arrowBox:hover .arrowRightClass {
  display: none;
}
.arrowBox:hover .arrowRightClassHover {
  display: block;
}

.getStartedButton {
  width: 200px;
  height: 50px;
  background-color: #33cdd1;
  box-shadow: 0px 26px 26px #47b6ba29;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.getStartedButton:hover {
  background-color: #04bbbf;
}
.getStartedText {
  font: 18px;
  color: #ffffff;
}
.playBackground {
  width: 64px;
  height: 64px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #00000029;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  position: absolute;
  margin-top: -231px;
  margin-left: 200px;
  cursor: pointer;
}
.groupsTimeRangeContainer {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-left: 15px;
  margin-right: 10px;
}

.todayAndTimeRangeContainer {
  display: flex;
  justify-content: flex-end;
}
.todayButtonDiv {
  display: flex;
  align-items: flex-end;
  margin-right: 30px;
}
.selectTimeRange {
  display: grid;
  justify-content: flex-end;
}
.tableHeaderContainer {
  display: grid;
  grid-template-columns: 40% 60%;
}
.CSVButtons {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 15px;
}
.groupName {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
@media (max-width: 1500px) {
  .playBackground {
    margin-left: 15%;
  }
}

@media (max-width: 1024px) {
  .gettingVideo {
    grid-template-columns: 100%;
  }
  .gettingVideoSecond {
    grid-template-columns: 100%;
  }
  .imageDiv {
    width: 57%;
  }
  .downloadExtension {
    padding: 0;
    margin: 30px 8px;
  }
  .arrowBox {
    margin: 30px 0px 0px 11px;
  }
  .playBackground {
    margin-left: 20%;
  }
  .extensionDiv {
    width: 48%;
  }
}

@media (max-width: 1020px) {
  .groupsTimeRangeContainer {
    grid-template-columns: 100%;
    margin-left: 15px;
    margin-right: 10px;
  }
  .todayAndTimeRangeContainer {
    display: grid;
    justify-content: flex-start;
    row-gap: 25px;
    margin-top: 30px;
  }
  .todayButtonDiv {
    margin-right: 0px;
  }
  .tableHeaderContainer {
    grid-template-columns: 100%;
  }
  .CSVButtons {
    display: grid;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 15px 0px 15px;
  }
}
.customNoOption {
  font-size: 14px;
  font-weight: 200 !important;
  display: flex;
  margin-left: 15px;
  align-items: center;
  height: 30px;
}
.dashboardHeader {
  display: flex;
  justify-content: space-between;
  /* margin-right: 60px; */
}
@media (max-width: 768px) {
  .dashboardHeader {
    display: grid;
    row-gap: 15px;
  }
}

/* Subscription Page  */

.subscontent {
  background-color: #f8f9fa;
  font-family: nova;
  color: #33475b;
  border-radius: 6px;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.subscontent .leftsubcontent {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
  /* background-color: #329e8a; */
}
.subscontent .rightsubcontent {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
  /* background-color: #6f7e7b; */
}

.subscontent .subheader {
  width: 100%;
  color: #4e4e4e;
  font-size: 16px;
}
.subscontent .subcenter {
  display: flex;
  justify-content: space-between;
}

.bottomsubcontainer {
  padding: 1.5rem 2.5rem;
  display: flex;
  gap: 4rem;
  color: #2a2c30;
  position: relative;
}

.subs-rate-container {
  display: flex;
  gap: 0.5rem;
}

.subs-rate {
  background-color: #fdf3e4;
  color: #ffa71f;
  font-size: 20px;
  padding: 0.3rem 1rem;
  font-weight: 500;
  border-radius: 12px;
  width: max-content;
}

@media screen and (max-width: 768px) {
  .subscontent {
    width: 100%;
  }
  .subs-rate-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 568px) {
  .subcenter {
    flex-direction: column;
  }
  .subscontent .rightsubcontent {
    width: 100%;
    align-items: start;
  }
  .subscontent .leftsubcontent {
    width: 100%;
  }
}
@media screen and (min-width: 568px) {
  .change-card {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
}

.paused-plan-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
}

.possible-reason {
  margin-top: 2rem;
}

.possible-reason .content {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  background-color: #e7ecef;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  align-items: center;
}

.possible-reason-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 15px;
  color: #2f394e;
  padding: 0.5rem 1rem;
}
.subs-rate-parent {
  display: flex;
  justify-content: space-between;
}
.paused-subs-rate-container {
  display: flex;
  gap: 0.5rem;
}

@media screen and (max-width: 568px) {
  .subs-rate-parent {
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .possible-reason .content {
    flex-direction: column;
    align-items: start;
  }
}

/* Cancel Modal */

.cancel-modal-body,
.upgrade-modal-body {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* Select first p child of p inside cancel-modal-body class */

.cancel-modal-body p:first-child {
  font-size: 19px;
  font-weight: 600;
  color: #33475b;
}
.cancel-modal-body p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  text-align: center;
}

.modal-content {
  position: relative;
}

.cross-icon {
  height: 30px;
  width: 30px;
  padding: 0.1rem;
  background-color: #f8f9fa;
  border-radius: 50%;
  position: absolute;
  right: -27px;
  top: -27px;
  cursor: pointer;
}

.modal-container-box {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
}

/* Upgrade Package Modal */

.upgrade-package {
  display: flex;
  width: 95%;
  gap: 2rem;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.price-box {
  padding: 1rem;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  width: 80%;
}

.check-icon {
  height: 25px;
  width: 25px;
  padding: 0.3rem;
  background-color: #b9c3e6;
  border-radius: 50%;
}

.feature-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: left;
}

.feature-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: 20px;
}

@media screen and (max-width: 1000px) {
  .upgrade-package {
    width: 100%;
    flex-direction: column;
  }
  .price-box {
    width: 100%;
  }

  .feature-container {
    margin: 0;
  }
}

.resume-subscription-btn {
  width: fit-content;
  margin-top: 5px;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: none;
  color: #fff;
  background-color: #33cdd1;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
}

.modal-container-box .pause-button {
  background-color: #05c7ca;
  color: #fff;
  border: none;
  width: fit-content;
  padding: 1rem 2.5rem;
  border-radius: 6px;
  transition: 0.3s;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.modal-container-box .cancel-button {
  border-bottom: 2px solid #080808;
  background-color: #fff;
  color: #33475b;
  border: none;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin-top: 1rem;
  border-bottom: #000 1px solid;
  cursor: pointer;
}

.dashboard_subscribe_button {
  margin-top: 5px;
  padding: 0.3rem 0.5rem;
  border-radius: 8px;
  border: none;
  color: #eec822;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  margin-left: 2rem;
}
